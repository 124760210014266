<template>
  <div>
    <v-menu :disabled="disabled" content-class="dropdown"  offset-y :close-on-content-click="false" >
      <template v-slot:activator="{ on, attrs }">
        <v-chip  v-bind="attrs"  v-on="on" small outlined
                 :color="disabled ? 'grey' : 'grey darken-3'">
          <v-icon small class="mr-1">mdi-clock</v-icon>
          {{ text }}
        </v-chip>
      </template>
      <v-sheet  class="d-flex justify-center" width="100" >

        <!-- hours-->
        <v-sheet v-if="!timeOver" class="overflow-y-auto"  max-height="150" width="50">
          <v-list dense>
            <v-list-item-group v-model="hours" mandatory >
              <v-list-item  v-for="(val,i) in hoursSelect" :key="i"
                            :value="val"
                            style=" min-height: auto !important; height: 22px !important"
                            class="py-0" dense   >
                <v-list-item-content>
                  <v-list-item-title class="text-center">{{ val }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
        <v-divider v-if="!timeOver" vertical></v-divider>
        <!-- minutes-->
        <v-sheet v-if="!timeOver" class="overflow-y-auto"  max-height="150" width="50">
          <v-list dense>
            <v-list-item-group v-model="minutes" mandatory >
              <v-list-item  v-for="(val,i) in minutesSelect" :key="i"
                            :value="val"
                            style=" min-height: auto !important; height: 22px !important"
                            class="py-0" dense  >
                <v-list-item-content>
                  <v-list-item-title class="text-center">{{ val }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>

        <v-sheet v-if="timeOver" class="pa-4">
          <span class="caption">Не має</span>
        </v-sheet>

      </v-sheet>

    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ftTimePicker",
  props : ['mStep','date','disabled','value'],
  data(){
    return {
      timeNew    : null,
      dateNow    : null,
      hours      : 0,
      minutes    : 0,
      hoursNow   : 0,
      minutesNow : 0,
      text       : '',
      currentDay : true,
      timeOver   : false,
      nextDateCount : 0
    }
  },
  computed : {
    minutesSelect() {
      var items = []

      for ( let i = 0; i<60 ; i++) {
          if ( i % this.mStep === 0 ) {
            items.push(i)
          }
      }

      return items;
    },
    hoursSelect() {
      var items = []
      for ( let i=0; i<= 24; i++) {
        if ( i < 24) {
          items.push(i)
        }
      }
      return items;
    },
    time() {

        let hours = this.hours > 9 ? this.hours : `0${this.hours}`
        let minutes = this.minutes > 9 ? this.minutes : `0${this.minutes}`
      if (!this.timeOver) {
        return hours + ':' + minutes
      } else {
        return null
      }

    },
  },
  watch: {
    time(val) {
      this.$emit('input',val)
      this.text=val
      if ( val === null) {
        this.text='Не обрано'
      }
    },
    value() {
      this.text === this.time
    },
    mStep() {
      this.validateProps()
    },
    date() {
      this.validateProps()
    }
  },
  methods : {


  },
  mounted() {
    if (!this.mStep) { this.mStep = 5 }
    if ( this.value) { this.text = this.value }
  }
}
</script>

<style scoped>
.dropdown {
  /*width: 100px !important;*/
  min-width: 50px !important;
}
</style>