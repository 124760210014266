<template>
  <div class="pa-16">

    <!-- Page title-->
    <div class="d-flex justify-space-between align-center mb-16">
      <h1 class="text-h4 font-weight-light ">Загальні налаштування</h1>
      <v-btn @click="saveChanges" rounded text large  color="teal">
        <v-icon class="mr-2">mdi-check</v-icon>Зберегти</v-btn>
    </div>

    <!--  PAge content-->
    <div style="max-width: 600px">

      <!--  General settings-->
      <div class="d-flex  align-center justify-space-between my-3">
        <div class="d-flex align-center">
          <v-btn icon @click="informationSettings = !informationSettings">
            <v-icon  >{{informationSettings ? 'mdi-chevron-up'  : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <h4 class="font-weight-medium">Загальні</h4>
        </div>
        <v-switch />
      </div>
        <v-expand-transition>
          <div v-if="informationSettings">
            <v-divider />
            <div class="px-6 mt-5 mb-5 ">


              <h4 class="mt-10">Інформація : </h4>
              <v-text-field v-model="business.name"
                            label="Назва"
                            class="mx-6 mt-8"
                            filled rounded  />
              <v-text-field v-model="business.alias"
                            label="Посилання"
                            class="mx-6"
                            filled rounded  />
              <v-textarea v-model="business.shortAbout"
                          label="Короткий опис"
                          class="mx-6 "
                          filled rounded  />

            </div>

            <v-divider />
          </div>
        </v-expand-transition>
      <!--  Work time settings-->
      <div class="d-flex  align-center justify-space-between my-3">
        <div class="d-flex align-center">
          <v-btn icon @click="workTimeSettings = !workTimeSettings">
            <v-icon  >{{workTimeSettings ? 'mdi-chevron-up'  : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <h4 class="font-weight-medium">Режим Роботи</h4>
        </div>
        <v-tooltip bottom max-width="400" color="#2e2e2e">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="teal"   v-bind="attrs"  v-on="on" > mdi-information-outline </v-icon>
          </template>
          <div class="mx-2 my-3">Робочі дні та часи вашого бізнесу</div>
        </v-tooltip>
      </div>
      <v-expand-transition>
        <div v-if="workTimeSettings">

          <v-sheet outlined class="px-3">

            <div class="d-flex justify-space-between align-center pl-3 py-2">
              <h5 class="font-weight-regular" >Має режим роботи  </h5>
              <v-switch class="compact-form mr-n3 mt-0" v-model="business.workDays.hasWorkDays" dense hide-details />
            </div>
            <v-divider />

            <v-simple-table style="width: 300px" class="my-3" dense>
              <tbody>

              <tr  v-for="(day,i) in business.workDays.items" :key="i">

                <td width="25px" class="font-weight-medium">
                  <div class="d-flex">
                    <v-checkbox :disabled="!business.workDays.hasWorkDays" v-model="day.active" dense hide-details class="my-0"> </v-checkbox>
                    {{ day.text }}
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <ftTimePickerSimple :disabled="!business.workDays.hasWorkDays" v-if="day.active" :m-step="5" v-model="day.start" class="mr-3"  />
                    <ftTimePickerSimple :disabled="!business.workDays.hasWorkDays" v-if="day.active" :m-step="5" v-model="day.end"   />
                  </div>
                  </td>
                <td></td>
              </tr>




              </tbody>
            </v-simple-table>

          </v-sheet>

        </div>
      </v-expand-transition>

      <!--  Regional settings-->
      <div class="d-flex  align-center justify-space-between my-3">
        <div class="d-flex align-center">
          <v-btn icon @click="regionalSettings = !regionalSettings">
            <v-icon  >{{regionalSettings ? 'mdi-chevron-up'  : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <h4 class="font-weight-medium">Регіональні налаштування</h4>
        </div>
        <v-tooltip bottom max-width="400" color="#2e2e2e">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="teal"   v-bind="attrs"  v-on="on" > mdi-information-outline </v-icon>
          </template>
          <div class="mx-2 my-3">Регіональні налаштування, країни в яких працює ваш бізнесс, мови, які підтримує ваш сайт, основну мову, валюту в якій ви рералізовуте свої товари</div>
        </v-tooltip>
      </div>
        <v-expand-transition>
        <div v-if="regionalSettings">
          <v-divider />
          <div class="px-6 mt-5 mb-5 ">

            <h4 class="mt-10">Локалізація : </h4>

            <v-text-field v-model="business.country"
                          label="Країна"
                          class="mx-6 mt-8"
                          filled rounded  />
            <v-text-field v-model="business.language_primary"
                          label="Основна мова"
                          class="mx-6"
                          filled rounded  />
            <v-select v-model="business.currency"
                      :items="currencies"
                      label="Валюта"
                      class="mx-6 "
                      filled rounded  />

            <div class="d-flex justify-space-between align-center">
              <h4 class="mt-10">Тільки на теритоії моєї країни : </h4>
              <v-switch color="teal" dense v-model="business.international" class="mr-5" hide-details />
            </div>

            <h4 class="mb-3 mt-5">Мови : </h4>
            <v-simple-table dense class="mt-5">
              <tbody>
              <tr v-for="(lang,i) in business.languages" :key="i">
                <td style="border:none" >
                  <div class="d-flex align-center">
                    <v-btn icon small @click="deleteLanguage(i)" class="mr-5">
                      <v-icon small color="grey " >mdi-close</v-icon>
                    </v-btn>
                    <span> {{ getLang(lang.lang) }}   </span>
                  </div>
                </td>
                <td style="border:none"  >
                  <div class="d-flex justify-end align-center ">
                    <v-switch color="teal" class="mt-n1" v-model="lang.active" dense hide-details/>
                  </div>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
              <div class="d-flex justify-end mt-3" v-if="languages.length > 0">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn rounded text color="teal" class="noCaps" v-bind="attrs" v-on="on">
                    <v-icon class="mr-1">mdi-plus</v-icon>
                    Додати мову
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item v-for="(lang, i) in languages" :key="i" @click="addLanguage(lang)">
                    <v-list-item-title>{{ lang }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

          </div>
          <v-divider />
        </div>
      </v-expand-transition>
      <!--  Locations content-->
      <div class="d-flex  align-center justify-space-between my-3">
        <div class="d-flex align-center">
          <v-btn icon @click="locationsSettings = !locationsSettings">
            <v-icon  >{{locationsSettings ? 'mdi-chevron-up'  : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <h4 class="font-weight-medium">Адреси</h4>
        </div>
      </div>
        <v-expand-transition>
        <div v-if="locationsSettings">
          <v-divider />
          <div class="px-6 mt-5 mb-5 ">
            <v-simple-table v-if="!displayNewLoaction" style="width: 100%">
              <tbody>
              <tr v-for="(location,i) in business.locations" :key="i">
                <td style="border: none">

                  <div class="d-flex align-center my-3">
                    <v-icon large color="teal" class="mr-3">mdi-map-marker</v-icon>
                    <div>
                      <h4> {{ location.address  }}   </h4>
                      {{ location.city.text  }} <br>
                      <span v-for="(phone,i) in location.phones " :key="i"
                          class="caption">{{ phone}} {{i !== location.phones.length-1 ? ',' : ''  }} </span>
                    </div>
                  </div>

                </td>
                <td style="border: none" class="text-right">
                  <div class="d-flex justify-end">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs"   v-on="on" >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item-group>
                          <v-list-item  v-for="(item, j) in lactionActions" :key="j"
                                        @click="locationAction(item.value,i)" >
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </div>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
              <div  class="d-flex justify-end mt-3" v-if="!displayNewLoaction">
                <v-btn rounded text color="teal" class="noCaps" @click="addLocation">
                  <v-icon class="mr-1">mdi-plus</v-icon>
                  Додати адресу
                </v-btn>
            </div>
            <v-sheet v-if="displayNewLoaction" class="mt-6">
              <div class="d-flex justify-space-between align-center mb-10">
                <h4 class=" ">Додати нову адресу : </h4>
              </div>

              <v-row class="mb-6" >
                <v-col cols="6">
                  <v-select
                      @change="newLocation.city = { text : '', value : null } "
                      v-model="newLocation.country"
                      :readonly="business.international"
                      :items="['Ukraine','Lituania','Estonia']"
                      rounded filled
                      hide-details
                      label="Країна"   />
                </v-col>
                <v-col cols="6">
                  <ftCitySelect v-if="newLocation.country === 'Ukraine' " :densed="true"
                                v-model="newLocation.city"/>
                  <v-text-field class="mb-0" hide-details v-model="newLocation.city.text" v-else rounded filled    label="Місто"  />
                </v-col>
                <v-col cols="12" >
                  <v-text-field class="mt-0" hide-details v-model="newLocation.address"  rounded filled   label="Адреса"   /></v-col>
                <v-col cols="8" >
                  <div v-for="(phone,i) in newLocation.phones" :key="i"
                       class="d-flex align-center mb-6">

                    <v-btn class="mr-4" @click="newLocation.phones.splice(i,1)"
                           small icon >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-text-field  v-model="newLocation.phones[i]"
                                   hide-details
                                   rounded filled label="Телефон"   />

                  </div>
                </v-col>
                <v-col cols="4" >
                  <div class="d-flex justify-end align-center">
                    <v-btn rounded text color="teal" class="noCaps mt-2" @click="newLocation.phones.push('')">
                      <v-icon>mdi-plus</v-icon>
                      Додати номер
                    </v-btn>
                  </div>

                </v-col>

              </v-row>

              <div class="d-flex justify-end">
                <v-btn rounded text color="grey" class="noCaps" @click="displayNewLoaction=false">
                  Cкасувати
                </v-btn>
                <v-btn rounded text color="teal" class="noCaps" @click="saveLocation">
                  Зберегти
                </v-btn>
              </div>

            </v-sheet>
          </div>
          <v-divider />
        </div>
      </v-expand-transition>

    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
// import imago from "@/components/UI/imago";
import ftCitySelect from "@/components/UI/ftCitySelect";
import ftTimePickerSimple from "@/components/UI/ftTimePickerSimple";

export default {
  name: "BusinessDashGeneral",
  components : {
    ftCitySelect,
    ftTimePickerSimple
  },
  data(){
    return {

      loading : false,

      business : {},
      newLocation : {
        country : '',
        phones  : []
      },

      payments : [],
      businessPayment : [],

      informationSettings : true,
      logoSettings : false,
      locationsSettings : true,
      regionalSettings : true,
      workTimeSettings : false,


      displayNewLoaction : false,
      editLocationMode : true,
      selectedLocation  : null,

      languages_data  : ['UA','EN','CN','DE','FR','PG','BE','US'],
      currencies : ['UAH','USD','EUR'],

      lactionActions : [
        {text : 'Редагувати', value : 'edit'},
        {text : 'Видалити',   value : 'delete'}
      ]


    }
  },
  watch : {
    'business.languages.length'(length){
      if (length === 1) {
        console.log(this.business.languages[0])
        this.business.languages[0].active = true
      }

    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    languages() {
      var languages = this.languages_data
      if (this.business.languages.length > 0) {
        languages = languages.filter( el =>
            this.business.languages.filter(lng =>
                lng.lang === el)
                .length ===  0
        )
      }

      return languages
    }
  },
  methods : {
    ...mapActions('business', [ 'GET_GENERAL_SETTINGS','SAVE_GENERAL_SETTINGS']),

    saveChanges(){
      this.loading = true
      this.SAVE_GENERAL_SETTINGS(
          { alias : this.selectedBusiness.alias,
            data  : this.business
          }).then((out)=>{
        console.log(out)
        if ( out.result ) {
          this.$store.state.business.selectedBusiness = this.business
          this.$store.state.business.businessAlias    = this.business.alias
          this.notify('Налаштування збережено')
        }
        this.loading = false
      }).catch(()=>{this.loading = false})
    },
    saveLocation(){
      if (!this.editLocationMode) {
        this.business.locations.push(this.newLocation)
        this.displayNewLoaction = false
      } else {
        this.business.locations[this.selectedLocation] = this.newLocation
        this.displayNewLoaction = false
        this.editLocationMode = false
        this.selectedLocation = null
      }

    },
    addLanguage(lang) {
      this.business.languages.push({lang : lang, active : false})
    },
    addLocation(){
      this.newLocation = {
                country : '',
                phones  : [],
                primary : false,
                city    : {
                  text : '',
                  value: ''
                }
      }
      this.displayNewLoaction = true
      this.newLocation.country = this.business.country
      this.newLocation.phones = ['']
    },
    locationAction(action,index) {
      switch(action) {
        case  'edit'  : this.editLocation(index)   ; break;
        case  'delete': this.deleteLocation(index) ; break;
      }
    },
    editLocation(index){
      this.selectedLocation = index
      this.newLocation = this.business.locations[index]
      this.displayNewLoaction = true
      this.editLocationMode = true

    },
    deleteLocation(index) {
      this.business.locations.splice(index,1)
    },
    addPhone(){
      this.newLocation.phones.push('')
    },
    deleteLanguage(index) {
     return this.business.languages.length === 1 ? this.notify("Помилка! Щонайменше 1 мова повинна бути встановлена") :
      this.business.languages.splice(index,1)

    },
    getLang(lang) {
      switch(lang) {
        case 'EN' : return 'English'
        case 'UA' : return 'Українська'
        default : return lang
      }
    }



  },
  mounted(){

    this.business = this.selectedBusiness

    this.loading = true
    this.GET_GENERAL_SETTINGS(this.selectedBusiness.alias).then((out)=>{
      if (out.result) {
        this.business = out.data
      }
    }).catch(()=>{this.loading=false})
  }
}
</script>

<style scoped>

</style>